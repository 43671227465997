import {
  animationDescription,
  OptionalString,
  SubscribesTo,
  PublishesTo,
  ModuleCategory,
  spacingSchema,
  sizingSchema,
  typographySchema,
  borderSchema,
  createComponentAdmin,
  SchemaTypeHelper,
} from '@backstage-components/base';

import {Static, Type} from '@sinclair/typebox';

export const reactName = 'Button';
export const name = reactName;
export const description = reactName;
const category: ModuleCategory = 'component';

export const schema = Type.Object(
  {
    children: OptionalString('Button Label'),
    ariaLabel: OptionalString('Button ARIA Label'),
    href: OptionalString('Link Url'),
    background: Type.Optional(
      Type.Object({
        buttonColor: OptionalString('Button Color'),
      })
    ),
    spacing: Type.Optional(spacingSchema),
    typography: Type.Optional(typographySchema),
    border: Type.Optional(borderSchema),
    sizing: Type.Optional(
      Type.Pick(sizingSchema, [
        'width',
        'height',
        'minWidth',
        'maxWidth',
        'minHeight',
        'maxHeight',
      ])
    ),
    hoverColor: OptionalString('Button Hover Color'),
  },
  {
    dependencies: {
      href: {
        properties: {
          isExternal: Type.Boolean({
            default: false,
            title: 'Open Link in New Tab?',
          }),
        },
      },
    },
  }
);

export const uiSchema = {
  'ui:groups': {
    'ui:template': 'tabs',
    sections: [
      [
        'Properties',
        [
          {
            'ui:template': 'accordion',
            sections: [
              [
                'Button Properties',
                ['children', 'aria-label', 'href', 'isExternal', 'hoverColor'],
              ],
            ],
          },
        ],
      ],
      [
        'Styling',
        [
          {
            'ui:template': 'accordion',
            sections: [
              ['Spacing', ['spacing']],
              ['Sizing', ['sizing']],
              ['Background', ['background']],
              ['Typography', ['typography']],
              ['Border', ['border']],
              ['Custom Styles', ['styleAttr']],
            ],
          },
        ],
      ],
      [
        'Animations',
        [
          {
            'ui:template': 'accordion',
            sections: [['Animations', ['animationStates']]],
          },
        ],
      ],
    ],
  },
  border: {
    borderColor: {
      'ui:widget': 'color',
    },
  },
  typography: {
    textColor: {
      'ui:widget': 'color',
    },
  },
  background: {
    buttonColor: {
      'ui:widget': 'color',
    },
  },
  spacing: {
    margin: {
      'ui:widget': 'SpacingWidget',
      'ui:options': {
        type: 'margin',
      },
    },
    padding: {
      'ui:widget': 'SpacingWidget',
      'ui:options': {
        type: 'padding',
      },
    },
  },
  sizing: {
    'ui:options': {
      columns: 2,
    },
    width: {
      'ui:widget': 'SizingWidget',
    },
    height: {
      'ui:widget': 'SizingWidget',
    },
    minWidth: {
      'ui:widget': 'SizingWidget',
    },
    maxWidth: {
      'ui:widget': 'SizingWidget',
    },
    minHeight: {
      'ui:widget': 'SizingWidget',
    },
    maxHeight: {
      'ui:widget': 'SizingWidget',
    },
  },
};

export const defaultFieldData: Static<typeof schema> = {
  border: {},
  sizing: {},
  spacing: {},
  children: 'Button Label',
  background: {buttonColor: '#EDF2F7'},
  hoverColor: '#EDF2F7',
  typography: {textColor: '#000000'},
};

const instructions = Type.Union([
  SubscribesTo({
    topic: `${reactName}:click`,
    description: 'Click button (virtually)',
  }),
  SubscribesTo({
    topic: `${reactName}:animationState`,
    description: animationDescription,
    meta: {
      stateName: Type.String(),
    },
  }),
  PublishesTo({
    topic: `${reactName}:on-click`,
    description: 'Button has been clicked',
  }),
]);

// TODO: continue threading instruction definitions through the app so we can have type instruction type checking in components.

export const ComponentDefinition = createComponentAdmin({
  id: '49615581-37a3-48ea-a547-fa4f7255e35e',
  reactName,
  name,
  slug: reactName,
  description,
  version: 1,
  defaultFieldData,
  slotConfiguration: {},
  schema,
  uiSchema,
  instructions,
  category,
})
  .withAnimationStates()
  .withStyles()
  .build();

export type SchemaType = SchemaTypeHelper<typeof ComponentDefinition> & {
  isExternal?: boolean;
};
